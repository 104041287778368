import { SEO } from "components/seo/SEO.component";
import { useRelatedPages } from "hooks/relatedPages.hook";
import linksService from "services/links.service";

import { CollegeCounseling } from "modules/college-counseling/CollegeCounseling.component";
import React from "react";
import { RELATED_PAGES_LABELS } from "components/constants";

const CollegeCounselingPage = () => {
  useRelatedPages([
    {
      label: RELATED_PAGES_LABELS.HIGH_SCHOOL,
      link: linksService.highSchool(),
    },
    {
      label: RELATED_PAGES_LABELS.INQUIRY_FORM,
      link: linksService.inquiryForm(),
      newTab: true,
    },
  ]);

  return (
    <>
      <SEO title="College Counseling" />
      <CollegeCounseling />
    </>
  );
};

export default CollegeCounselingPage;
