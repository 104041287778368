import { ButtonLink } from "components/button-link/ButtonLink.component";
import { BlueGrouping } from "components/color-groupings/blue-grouping/BlueGrouping.component";
import { WhiteGrouping } from "components/color-groupings/white-grouping/WhiteGrouping.component";
import { Link } from "components/link/Link.component";
import { TeamMember } from "components/page-specific-components/college-counseling/components/TeamMember/TeamMember.component";
import { ImageHeading } from "components/page-specific-components/schools/components/image-heading/ImageHeading.component";
import { SVGIcons } from "components/svg-wrapper/SvgWrapper.enum";
import { StaticImage } from "gatsby-plugin-image";
import StaceyHo from "images/jpg/staff/Stacey_Ho.jpg";
import React from "react";
import linksService from "services/links.service";
import "./CollegeCounseling.styles.scss";
import { COUNSELING_TEAM_HEADING } from "./constants";

export const CollegeCounseling = () => {
  const collegeCounselingEmail = "sho@pacbay.org";

  return (
    <main>
      <WhiteGrouping>
        <section className="fix-header-overlap">
          <ImageHeading heading="College Counseling">
            <StaticImage
              src="../../images/jpg/graduation_2022.jpg"
              alt="students at graduation"
              placeholder="blurred"
              layout="constrained"
              className="college-counseling-header-image-margining"
            />
          </ImageHeading>
        </section>
        <section className="layout">
          <h3>For Colleges</h3>
          <div className="button-link-wrapper">
            <ButtonLink
              to={linksService.schoolProfile()}
              newTab={true}
              icon={SVGIcons.Document}
            >
              View School Profile
            </ButtonLink>
            <ButtonLink
              to={linksService.collegeLogin()}
              newTab={true}
              icon={SVGIcons.Visit}
            >
              Schedule A Visit
            </ButtonLink>
            <ButtonLink
              to={linksService.googleSchoolDirections()}
              newTab={true}
              icon={SVGIcons.Map}
            >
              Directions To Campus
            </ButtonLink>
          </div>
          <address>
            1030 Linda Mar Boulevard
            <br />
            Pacifica, CA 94044
          </address>
          <p className="arrival-instructions">
            When you arrive on campus, turn right, following signs to the
            "Office." Park in any open spot that you see. None are restricted,
            and parking is ample. You will check in at the Florence Gross
            Memorial Library (aka "The Community Center") where you will sign in
            and answer a quick Covid safety survey. If you are unable to find a
            timeslot on Scoir that works for you, please reach out to Stacey Ho
            (
            <Link to={`mailto:${collegeCounselingEmail}`}>
              {collegeCounselingEmail}
            </Link>
            ) to see if we can work something out. We look forward to your
            visit!
          </p>
        </section>
      </WhiteGrouping>
      <WhiteGrouping>
        <section className="layout">
          <h3>PacBay CEEB/ACT Code:</h3>
          <h4>052311</h4>
        </section>
      </WhiteGrouping>
      <BlueGrouping>
        <section className="layout" style={{ paddingBottom: "5rem" }}>
          <h3>{COUNSELING_TEAM_HEADING}</h3>
          <div className="team-member-wrapper">
            <TeamMember
              image={StaceyHo}
              name="Stacey Ho"
              title="Director of College Counseling"
              email="sho@pacbay.org"
              phoneNumber="6503551935,130"
              phoneNumberLabel="650-355-1935 ext.130"
            />
          </div>
        </section>
      </BlueGrouping>
    </main>
  );
};
