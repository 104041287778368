import { Link } from "components/link/Link.component";
import { Avatar } from "components/staff-card/components/avatar/Avatar.component";
import React from "react";
import "./TeamMember.styles.scss";

export const TeamMember = ({
  image,
  name,
  title,
  email,
  phoneNumber,
  phoneNumberLabel,
}) => {
  return (
    <article className="team-member">
      <Avatar dimension={7.5} image={image} />
      <div className="information-wrapper">
        <h5 className="no-margin">{name}</h5>
        <i>{title}</i>
        {email && (
          <div>
            <Link to={`mailto:${email}`}>{email}</Link>
          </div>
        )}
        {phoneNumber && phoneNumberLabel && (
          <div>
            <Link to={`tel:+1${phoneNumber}`}>{phoneNumberLabel}</Link>
          </div>
        )}
      </div>
    </article>
  );
};
